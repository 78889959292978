.overlayHT {
    position:relative;
    left: 250;          /* will be adjusted to match canOverlay object */
    top: 40;
    z-index:1250;
}

.MuiDialog-container {
    align-items: flex-start !important;
}

p {
    margin-top: 3pt;
    margin-bottom: 0pt;
}
.sfm-p {
    text-indent: 1em;
}
.sfm-c {
    font-size: 1.4em;
    font-weight: 750;
    position: static;
    margin-left: -0.7em;
    margin-bottom: -0.1em;
    line-height: 0.8em;
}
.sfm-h {               /* major title */
    font-size: 120%;
    font-weight: 750;
    margin-top: 1em;
    margin-bottom: 0.25em;
}
.sfm-s1 {               /* section heading */
    font-size: 85%;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0em;
}
.sfm-s {               /* same as section heading */
    font-size: 90%;
    font-weight: 600;
    margin-top: 0.6em;
    margin-bottom: 0em;
}
.sfm-r {
    vertical-align: super;
    font-style: italic;
    font-size: 0.8em;
    margin-top: -0.2em;
    margin-bottom: 0.2em;}
.sfm-verse {
    vertical-align: super;
    font-size: 0.7em;
    font-weight: 500;
    padding-left: 0.8em;
    padding-right: 0.25em;
    color:#4060ff;
}
.sfm-verse:first-child {
    padding-left: 0em;
}
.sfm-pi {
    text-indent: 1em;
    margin-left: 1em;
}
.sfm-m {                /* un-indented paragraph */
    text-indent: 0em;
}
.sfm-mi {               /* un-indented inset paragraph */
    margin-left: 1em;
}
.sfm-li {
    margin-left: 1em;
}
.sfm-li1 {
    margin-left: 1em;
}
.sfm-li2 {
    margin-left: 1em;
}
.sfm-q {
    margin-left: 1em;
}
.sfm-q1 {
    margin-left: 1em;
}
.sfm-q2 {
    margin-left: 2em;
}
.sfm-ms {
    font-size: 120%;
    font-weight: 750;
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.sfm-ms1 {
    font-size: 120%;
    font-weight: 750;
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.sfm-d {
    vertical-align: super;
    font-size: 0.7em;
    font-weight: 500;
}
.sfm-add {
    font-style: italic;
}
.sfm-bk {
    font-style: italic;
}
.sfm-fig {
    font-style: italic;
}
.sfm-footnote {
    position: static;
    color:blueviolet;
    font-size: 120%;
    padding: 0.2em;
    margin-left: 0;
    cursor: pointer;
}
.sfm-footnote .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 150px;
    top: 40%;
    left: 40%;
    font-size: 75%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid grey;
    background-color: #EEECEF;
    color:blueviolet;
    z-index: 1;
}
.sfm-footnote:hover .tooltiptext {
    visibility: visible;
}

.loader {
    width: 30px;
    height: 30px;
    margin: 15px;
    border-radius: 50%;
    display: inline-block;
    position: static;
    border: 3px solid;
    border-color: #333 #777 #aaa #ddd;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
